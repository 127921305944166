import "../css/lib.css";
import "../css/main.css";
import "../css/normalize.css";
import "../style.css";
import {graphql} from 'gatsby';
import React, { useState , useEffect } from "react";
import { useLocation } from '@reach/router';
import { RiseLoader } from 'react-spinners'
import { questionsDataStoreOffline } from "../data";
import { useToast } from "@chakra-ui/react";
import { QuestionV2Component, RatingV2Component} from "../components/index";
import { ThankYouComponent } from "../components/thank-you";
import { HeaderComponent } from "../components/header/index"
 import { useStyles } from "../style";
import { Question, QuestionStoreOffline } from "../types";
import axios from 'axios';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Select, { DropdownIndicatorProps, StylesConfig, components } from 'react-select';

type OptionType = {
  value: string;
  label: string;
};

const DropdownIndicator = (props: DropdownIndicatorProps<any, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="13"
        height="9"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1L5 6L9 1" stroke="black" strokeWidth="1" />
      </svg>
    </components.DropdownIndicator>
  );
};

const customStyles: StylesConfig<OptionType> = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: '1px solid #000',
    borderRadius: "8px",
    color: '#000',
    boxShadow: 'none',
    minHeight: '32px',
    height: '32px',
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    width: '100%',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    width: '100%',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(5px)',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? 'rgba(0, 0, 0, 0.1)'
      : 'transparent',
    color: '#000',
    cursor: 'pointer',
  }),
};



function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const options = [
  { value: 'vi', label: 'Tiếng Việt' },
  { value: 'en', label: 'English' },
]

// markup
const StoreRatings = ( ) => {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const toast = useToast();

  const findCurrentOption = (lang: string) => {
    return options.find(option => option.value === lang) || options[0]; // Fallback to first option
  };

  const storeCode = queryParams.get('store_code');{}
  const [isSubmit , setIsSubmit] = useState<boolean>(false)
  const [rating, setRating] = useState<number>(-1);
  const [loading , setLoading] = useState<boolean>(false)
  const [result , setResult] = useState<any>();
  const [questionsDataObject , setQuestionsData] = useState<QuestionStoreOffline[]>(questionsDataStoreOffline);
  const {language ,  changeLanguage} = useI18next();
  const [selectedOption, setSelectedOption] = useState(findCurrentOption(language));

  const handleLanguageChange = (optionValue: any) => {
    setSelectedOption(optionValue);
    changeLanguage(optionValue.value);
  };

  useEffect(() => {
    const currentOption = findCurrentOption(language);
    setSelectedOption(currentOption);
  }, [language]);

  useEffect(() => {
    const data = sessionStorage.getItem("ratingData");
    if (data) {
      setResult(JSON.parse(data));
      setIsSubmit(!isSubmit);
    }
  }, []);
   const showErrorMsg = async (msg: string): Promise<void> => {
    toast({
      title: msg || ``,
      status: `error`,
      duration: 2000,
      isClosable: false,
    });
  };

  const sumitData = (data: { [key: string]: any }): void => {
    let payload:{ [key: string]: any } = {};
    if (rating <= 0) {
      showErrorMsg(`Xin hãy chọn đánh giá trải nghiệm!`);
      return;
    }
     if(rating) {
      payload.rating = rating;
    }
    if(storeCode) {
      payload.storeCode = storeCode;
    }
    if(data.y_kien_khac) {
      payload.messages = data.y_kien_khac;
    }
    if(data) {
      delete data.y_kien_khac;
      payload.options = data;
    }
    
    setLoading(true)
    axios.post(process.env.GATSBY_END_POINT + '/ecom-frontend/v1/create-store-review', payload)
    .then(function (response) {
       if(response.status == 200) {
        setLoading(false)
        setIsSubmit(!isSubmit)
        setResult(response)
        sessionStorage.setItem("ratingData", JSON.stringify(response));

      }
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const selectRating = async (value: number): Promise<void> => {
    setRating(value);
  };

  return (
    <main className={classes.body}>
      <HeaderComponent/>
      {isSubmit ? 
        <ThankYouComponent 
          rating={rating} 
          type="store" 
          response={result}
        /> : 
        <div className="container_store_ratings_v2">
          {rating <= 0 &&
            <div className="flex justify-end mb-[34px] mt-[17px] mr-[21px]">
              <Select
                styles={customStyles}
                className="text-[#000000] w-[120px]"
                options={options}
                defaultValue={options[0]}
                value={selectedOption}
                onChange={(optionValue) => handleLanguageChange(optionValue)}
                components={{ DropdownIndicator }}
              />
            </div>
          }
          <div className={"mt-[40px]"}>
            <RatingV2Component 
              rating={rating} 
              selectRating={selectRating} 
              type="store"
              language={selectedOption?.value}
            />
          </div>
          {rating > 0 &&
            <QuestionV2Component
              submitQuestion={sumitData}
              questionsData={questionsDataObject}
              rating={rating}
              type="store"
              placeholder="Ý kiến khác"
            />
          }
          
          {loading && 
            <div className={classes.overlayLoading}>
              <RiseLoader color="#fff" style={{'display':'flex' , 'justifyContent':'center' , 'alignItems':'center' , 'height':'100vh'}} />
            </div>
          }
        </div>
      }
    </main>
  );
};

export default StoreRatings;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;